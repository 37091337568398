@import url(https://use.typekit.net/cbu5jyn.css);
@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@500&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@600&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@800&display=swap);

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color:'black';
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


body, #root {
  background-color:black;
  color:white;
}

.main-header{
  font-family: Poppins;
  font-size:32px;
  margin:0;
  margin-top:8px;
  line-height:38px;
  font-weight:500;
}

#request-download {
  font-size:16px;
  font-family: Poppins;
  font-weight:600;
}

#app-download-button {
  background: linear-gradient(262.89deg, #F4D0F5 5.52%, #BAF0FC 88.37%);
border-radius: 40px;
font-weight:600;
font-size:18px;
height:45px;
line-height:22px;
font-family: Poppins;
color:black;
display:-webkit-inline-flex;
display:inline-flex;
-webkit-align-items: center;
        align-items: center;
cursor:pointer;
-webkit-flex-direction: row;
        flex-direction: row;
padding-left:20px;
border:11px solid #1F1F1F;
padding-right:20px;
}

#insta-button {
  background: white;
border-radius: 40px;
font-weight:600;
font-size:16px;
line-height:8px;
font-family: Poppins;
color:black;
display:-webkit-inline-flex;
display:inline-flex;
-webkit-align-items: center;
        align-items: center;
cursor:pointer;
margin-left:16px;
-webkit-flex-direction: row;
        flex-direction: row;
padding-left:20px;
padding-right:20px;
}

#launch-cities > *  {
  margin:0;
  padding:0;

}

#launch-cities {
  padding:20px;
  border-left:1px solid #38394A;
}

#download-request-container {
  max-width:400px;
  margin:0 auto;
  margin-top:42px;
}

#confirm-check {
  width:24px;
  height:24px;
  border-radius:12px;
}

#qr-hover {
  width:24px;
  height:24px;
  cursor:none;
  z-index:9999999;
  background-color: white;
  border-radius:4px;
  transition: width 316ms, height 316ms, border-radius 316ms, -webkit-transform 316ms;
  transition: width 316ms, height 316ms, border-radius 316ms, transform 316ms;
  transition: width 316ms, height 316ms, border-radius 316ms, transform 316ms, -webkit-transform 316ms;
}
#qr-hover:hover {
  -webkit-transform:scale(6);
          transform:scale(6);
  border-radius:5px;
}



#launch-cities h4 {
  font-size:12px;
}
#launch-cities h3 {
  font-weight:700;
  font-size:20px;
}
#app-download-button img, #insta-button img {
  display:inline-block;
  margin-right:8px;
}



@-webkit-keyframes animationgradienttitle {
  0% {
    background-position: 0 1600px;
  }
  100% {
    background-position: 1600px 0;
  }
}



@keyframes animationgradienttitle {
  0% {
    background-position: 0 1600px;
  }
  100% {
    background-position: 1600px 0;
  }
}

.text-gradient {
  font-weight:700;
  background: -webkit-linear-gradient(
    left,
    #EDCEF6, #DDD9F8, #BDEFFC, #DDD9F8
  );
  background-size: 1600px 200px;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  -webkit-animation-duration: 3s;
          animation-duration: 3s;
  -webkit-animation-name: animationgradienttitle;
          animation-name: animationgradienttitle;
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
}


.btn-bg {
  width:100px;
  height:40px;
  position:absolute;
}
#web-logo-top {
  text-align:center;
  width:100px;
  display:inline-block;
}
#float-logo-block {
  z-index:9999999;
  position: relative;
}
#activity-container {
  width:400px;
  margin:0 auto;
}

#activity-title {
  padding:0 16px;
  font-size:16px;
}

#invite-activity-title {
  text-align:left;
  line-height:34px;
  margin:0;
  margin-left:12px;
  font-size:15px;
}

#header-bar {
  padding-top:16px;
  padding-bottom:16px;
  width:100%;
  margin-bottom:60px;
  background: rgba(27, 27, 48, 0.37);
}

.header-slogan {
  font-family: Urbane;
  font-size:12px;
  margin-top:-1px;
  margin-left:12px;
  line-height:32px;
  vertical-align: middle;
  display:inline-block;
  font-weight:500;
}

.header-icon-grid {
  display: grid;
  border-radius:20px;
  background: rgb(22, 22, 38);
box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.16);
  grid-template-columns: 73px 26px;
  width:106px;
  height:32px;
  cursor:pointer;
  margin-top:-3px;
  padding-left:16px;
}

.header-icon-grid:hover {
  background: rgb(27, 27, 43);
}



.app-link {
  color: #37C3FF;
  line-height:32px;
  font-size:11px;
  font-weight:600;
  font-family: Urbane, sans-serif;
}

.header-icon {
  width:22px; height:32px;
  text-align:center;
}

.header-icon img {
  margin-top:8px;
  width:14px; height:14px;
  vertical-align: middle;
}

#header-bar-inner {
  display:-webkit-flex;
  display:flex;

  height:26px;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  grid-column-gap:0px;
  -webkit-column-gap:0px;
          column-gap:0px;
  max-width:400px;
  margin:0 auto;
}

input:focus, input {
  outline-offset: 0;
  outline:none;
  border:none;
  -webkit-appearance: none;
  outline-width: 0;
}


#invite-header {
  font-weight:600;
  font-size:20px;
  line-height:24px;
  margin:0;
  font-family: Urbane, sans-serif;
}
input {
  display:block;
}
.App-logo {
  height: 40vmin;
  pointer-events: none;
}
.multi-select-item {
  border:1px solid;
  display: inline-block;
  padding:16px 20px;
  cursor:pointer;
}
.top-bottom-pad {
  margin-top:40px;
  margin-bottom:20px;
}
.selected {
  border-color:lightgreen;
  border-width:3px;
}


#main-view {
  height:100vh;
  position:fixed;
  left: 50%;
  width:90%;
  max-width:500px;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
}

.input-container {
  width:100%;
  max-width:500px;
  border-radius:12px;
  background: rgba(27, 27, 48, 0.37);
border: 2px solid #38394A;
border-radius: 8px;
}

.phone-country {
  display:inline;
}

.phone-number-input {
}

select {
  background:none;
  color:white;
  -webkit-appearance: none;
  border:none;
  font-size:16px;
  padding-left:16px;
  padding-right:16px;
  border-radius:0px;
  padding-top:0;
  margin-top:0;
  margin-bottom:0;
}
.phone-country-container {
  display:grid;
  grid-template-columns: 105px 1fr;
}

#qr-grid {
  display:grid;
  margin-top:16px;
  grid-column-gap:16px;
  -webkit-column-gap:16px;
          column-gap:16px;
  grid-template-columns: 120px 1fr;
}

.country-thing {
  border-right:1px solid rgba(56, 57, 74, 0.509);
  padding-top:16px;
}

.flag-container-phone-input {
  margin-left:16px;
  vertical-align: middle;
  margin-right:10px;
  display:inline-block;
}

.selected-country-dial-code {
  font-weight:600 !important;
}

.phone-country {
  opacity:.1;
  width:50px;
  margin-left:-16px;
  margin-top:-8px;
  height:40px;
  position:absolute;
}

a, a:visited {
  text-decoration:none;
  color: #37C3FF;
}

#verification-code-container {
  display:grid; 
  grid-template-columns: 40px 40px 40px 40px;
  height:50px; 
  margin-bottom:12px;
  grid-column-gap:12px;
  -webkit-column-gap:12px;
          column-gap:12px;
}

#verification-code-container input {
  width:40px;
  height:50px; 
  background: rgba(27, 27, 48, 0.37);
  border: 2px solid #38394A;
  border-radius: 8px;
  text-align:center;
  line-height:30px;
  font-size:20px;
  color:white;
}

input {
  caret-color: #37C3FF;
  caret-width:2px;
}

.terms-p {
  font-size:11px;
  font-weight:500;
}
.input-container input {
  background:none;
  color:white;
  placeholder-color: #767789;
  font-size:16px;
  padding-top:10px;
  padding-bottom:10px;
  padding-left:16px;
  height:32px;
}

::-webkit-input-placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #767789;
  opacity: 1; /* Firefox */
}

:-ms-input-placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #767789;
  opacity: 1; /* Firefox */
}

::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #767789;
  opacity: 1; /* Firefox */
}

:-ms-input-placeholder { /* Internet Explorer 10-11 */
  color: #767789;
}

::-ms-input-placeholder { /* Microsoft Edge */
  color: #767789;
}

.input-divider {
  width:100%;
  background-color:#38394A;
  height:1px; 
}

.input-container input:focus {
 outline:none;
 border:none;

}
.tile-avatar {
  background-color: rgb(23, 24, 34);
  width:26px;
  height:26px;
  border-radius:30px;
  overflow:hidden;
  border:4px solid #1D1F2D;
}

.tile-avatar img {
  width:100%;
}

.join-circles {
  display:grid;
  margin:0 auto;
  grid-column-gap: -20px;
  -webkit-column-gap: -20px;
          column-gap: -20px;
}

.join-circles {
  display:grid;
  margin:0 auto;
  grid-column-gap: -20px;
  -webkit-column-gap: -20px;
          column-gap: -20px;
}

.invite-join-circles {
  display:grid;
  margin:0;
  margin-left:20px;
  grid-column-gap: -20px;
  -webkit-column-gap: -20px;
          column-gap: -20px;
}

#redirect-header {
  max-width:400px;
  width:80%;
  font-family: Poppins;
  margin:0 auto;
}

.hidden-text {
  top:-30px;
  position:absolute;
  display:none;
}


#phoneBox {
  margin:0 auto !important;
  width:100%;
  position:static !important;
  max-width:460px;
}

#appstore-btn {
  width:100px;
  height:50px;
  background:green;
  top:100px;
}
#essay-blur {
  background: rgba(0,0,0,.7);
  box-shadow: -1px 0px 61px 103px rgba(0,0,0,0.75);
  -webkit-box-shadow: -1px 0px 61px 103px rgba(0,0,0,0.75);
  -moz-box-shadow: -1px 0px 61px 103px rgba(0,0,0,0.75);
  z-index:999;
  position: relative;
  padding-top:100px;
  padding-bottom:100px;
  -webkit-backdrop-filter: blur(7px);
          backdrop-filter: blur(7px);
}

#essay-blur p {
  max-width:500px;
  margin:0 auto;
}

.tile-block {
  background: linear-gradient(197.41deg, #1B1B22 -12.15%, #1B1B32 9.88%, #161B28 97.27%, #434B5F 103.76%);
  box-shadow: 0px 12px 47px rgba(0, 0, 0, 0.78);
  border-radius:20px;
  margin:0 auto;
  padding-top:20px;
  -webkit-animation-duration: .8s;
          animation-duration: .8s;
  min-width:165px;
  position:relative;
}

.invite-tile-top {
  display:grid;
  padding-left:20px;
  padding-right:20px;


  grid-template-columns: 32px 1fr;
}

#lc-block {
  max-width:400px !important;
  border:1px solid white;
  border-radius:8px;
  margin:0 auto;
}
.invite-tile-block {
  background: linear-gradient(197.41deg, rgba(29, 29, 32, 0.82) -12.15%, rgba(16, 16, 32, 0.82) 9.88%, rgba(16, 20, 29, 0.82) 97.27%, rgba(31, 32, 35, 0.82) 103.76%);
  box-shadow: 0px 12px 47px rgba(0, 0, 0, 0.78);
  border-radius:20px;
  margin:0 auto;
  padding-top:20px;
  -webkit-animation-duration: .8s;
          animation-duration: .8s;
  min-width:165px;
  position:relative;
}

.shadow-hover:hover {
  box-shadow: 10px 10px 89px -29px rgba(74, 140, 255, 0.2);
}

.tb-animated {
  transition: opacity .8s ease-in-out;
  -webkit-animation-iteration-count:1;
          animation-iteration-count:1;
  margin-left:115px;
  -webkit-transform: perspective(0px) rotateY(0deg);
          transform: perspective(0px) rotateY(0deg);
  -webkit-transform-origin: bottom;
          transform-origin: bottom;
  position: absolute;
  -webkit-animation-duration: .8s;
          animation-duration: .8s;
}

#rotation {

  -webkit-animation: rotation 120s infinite linear;
  animation: rotation 120s infinite linear;
}

#invite-emoji {
  background-color:rgba(255,255,255,.06);
  border-radius:20px;
  width:34px;
  height:34px;
  font-size:16px !important;
  line-height:34px;
}

.tb-animated:hover, .tb-animated-start {
  -webkit-animation-name: slideout;
          animation-name: slideout;
  opacity:0;
  -webkit-animation-timing-function: ease-in-out;
          animation-timing-function: ease-in-out;
}

@-webkit-keyframes slideout {
  0%   { -webkit-transform: translateX(0) rotateZ(0deg) perspective(0px) rotateY(-1deg); transform: translateX(0) rotateZ(0deg) perspective(0px) rotateY(-1deg);}
  100%  { -webkit-transform: translateX(-200px) rotateZ(5deg) perspective(5000px)rotateY(-56deg); transform: translateX(-200px) rotateZ(5deg) perspective(5000px)rotateY(-56deg); }
}

@keyframes slideout {
  0%   { -webkit-transform: translateX(0) rotateZ(0deg) perspective(0px) rotateY(-1deg); transform: translateX(0) rotateZ(0deg) perspective(0px) rotateY(-1deg);}
  100%  { -webkit-transform: translateX(-200px) rotateZ(5deg) perspective(5000px)rotateY(-56deg); transform: translateX(-200px) rotateZ(5deg) perspective(5000px)rotateY(-56deg); }
}

.tb-animated-in {
  margin-left:315px;
  position:absolute;
  transition: opacity .8s ease-in-out;
  -webkit-animation-iteration-count:1;
          animation-iteration-count:1;
  -webkit-transform: perspective(5000px)rotateY(56deg);
          transform: perspective(5000px)rotateY(56deg);
  -webkit-transform-origin: bottom;
          transform-origin: bottom;
  opacity:0;
  -webkit-animation-duration: .8s;
          animation-duration: .8s;
  -webkit-animation-fill-mode: forwards;
}

.tb-animated-in:hover, .tb-animated-in-start {
  -webkit-animation-name: slidein;
          animation-name: slidein;
  opacity:1;
  -webkit-animation-timing-function: ease-in-out;
          animation-timing-function: ease-in-out;
}

@-webkit-keyframes slidein {
  
  0%   { -webkit-transform: translateX(0) rotateZ(5deg) perspective(5000px)rotateY(56deg); transform: translateX(0) rotateZ(5deg) perspective(5000px)rotateY(56deg); }
  100%  { -webkit-transform: translateX(-200px) rotateZ(0deg) perspective(0px) rotateY(-1deg); transform: translateX(-200px) rotateZ(0deg) perspective(0px) rotateY(-1deg);}
}

@keyframes slidein {
  
  0%   { -webkit-transform: translateX(0) rotateZ(5deg) perspective(5000px)rotateY(56deg); transform: translateX(0) rotateZ(5deg) perspective(5000px)rotateY(56deg); }
  100%  { -webkit-transform: translateX(-200px) rotateZ(0deg) perspective(0px) rotateY(-1deg); transform: translateX(-200px) rotateZ(0deg) perspective(0px) rotateY(-1deg);}
}


.bottom-tile-area {
  background-color:rgba(255,255,255,.02);
  border-top:1px solid rgba(255, 255, 255, 0.057);
  padding-top:20px;
  padding-bottom:20px;
}

@-webkit-keyframes rotation {
  from {
      -webkit-transform: rotate(0deg);
  }
  to {
      -webkit-transform: rotate(359deg);
  }
}
@keyframes rotation {
  from {
      -webkit-transform: rotate(0deg);
              transform: rotate(0deg);
  }
  to {
      -webkit-transform: rotate(359deg);
              transform: rotate(359deg);
  }
}
.drop-borders {
  border:none !important;
}

.invite-bottom-tile-area {
  display:grid;
  background-color:rgba(255,255,255,.02);
  border-top:1px solid rgba(255, 255, 255, 0.057);
  padding-top:20px;
  padding-bottom:20px;
}

.invite-location-block {
  display:grid;
  padding-left:20px;
  padding-right:20px;
  margin-top:12px;
  font-weight:400;
  margin-bottom:16px;
  grid-template-columns: 32px 1fr;
}

.Tilt {
  height:auto !important;
}
#invite-location-title {
  margin:0;
  font-weight:500;
  text-align:left;
  font-size:13px;
  margin-left:12px;
  opacity:.9;
  
}
#location-title {
  border-top:1px solid rgba(255,255,255,.06);
  padding-bottom:0;
  margin-bottom:0;
  line-height:48px;
}

.prompt-p {
  font-weight:500;
  margin:0;
  padding:0;
  font-size:14px;
}

.tile-btn, .default-btn {
  display:inline-block;
  padding-left:16px;
  cursor:pointer;
  font-family:Urbane;
  padding-right:16px;
  font-size:12px;
border-radius:40px;
font-weight:600;
line-height:32px;
height:32px;
border:3px solid #5FE71F;

background: rgba(96, 182, 56, 0.221);
}
#index-tile {
  position: relative !important;
  top:-350px;
  display:-webkit-flex;
  display:flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  border:1px solid salmon;
  margin:0 auto !important;
  height:200px;
  background-color: rgba(102,77,201, 0.2);
  max-height:800px;
  width:80%;
}


#essay-container {
 padding-top:100vh;
 padding-bottom:100vh;
}
.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.show-on-mobile-block {
  display:none;
}





@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}





@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}


@media only screen 
  and (min-device-width: 320px) 
  and (max-device-width: 480px)
  and (-webkit-min-device-pixel-ratio: 2) {

  .hide-on-mobile {
    display:none;
  }
  #activity-container {
    display:grid;
    margin-bottom:80px;
    grid-template-columns: 1fr;
    max-width:90%;
  }

  .input-container {
    width:100%;
    max-width:100%;
  }
  #header-bar-inner {
    width:90%;
  }

  .terms-p {
    text-align:left;
  }

  #header-bar {
    margin-bottom:10px;
  }

  #invite-header {
    width:80%;
    margin-top:20px;
  }

  .show-on-mobile-block {
    display:block;
  }

  .prompt-p {
    text-align:left;
    margin-bottom:8px !important;
  }
  .default-btn {
    padding:0;
    text-align:center;
    border-radius:60px;
    border-width:3px;
    line-height:36px;
    height:36px;
    width:100px;
    display:block;
    margin:0 auto !important;
  }

  #button-terms-container {

  }

  

  #download-request-container {
    max-width:100%;
    padding-left:20px;
    margin-bottom:120px;
    padding-right:20px;
    box-sizing: border-box;
  }

  .drop-borders {
    border:none !important;
  }

  #terms-container {
    text-align:center;
  }

  .top-margin-mobile-20 {
    margin-top:20px;
  }

  #emoji, #invite-emoji {
    font-size:14px !important;
  }



  #qr-grid {
    grid-template-columns: 1fr;

  }
  #verification-code-container {
    display:grid; 
    grid-template-columns: 50px 50px 50px 50px;
    height:50px; 
    margin-bottom:12px;
    grid-column-gap:12px;
    -webkit-column-gap:12px;
            column-gap:12px;
  }

  #app-download-button {
    font-size:16px;
  }

  #instagram-button {
    font-size:12px;
  }
  
  .main-header{
    font-family: Poppins;
    font-size:24px;
    line-height:34px;
    margin-left:12px;
    font-weight:500;
  }

  #qr-landing {
    padding-left:14px;
  }
  #verification-code-container input {
    width:30px;
    height:44px; 
    background: rgba(27, 27, 48, 0.37);
    border: 2px solid #38394A;
    border-radius: 8px;
    text-align:center;
    line-height:30px;
    font-size:20px;
    color:white;
  }

}

